input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.message-chars-left {
  width: 100%;
  margin: auto;
  text-align: center;
}
.error{
    font-size:small;
    color:red;
    display:inline;
}
.label{
    text-align:left;
}
.input{
    display:flex;
    color:black;
    position:relative;
    min-width: 80px;
    justify-content:center;
}
form{
    background-color: #ccc;
    opacity: .8;
    border:1px solid black;
    border-radius: 20px 0;
    text-align: left;
    color:#000;
    display:flex;
    flex-direction: column;
    gap:1.5em;
    z-index:10;
    width: 50%;
    margin: 2em auto;
    padding: 10px;
    position: relative;
    justify-content: center;
    align-items: center;
}
form h1{
    color:black
}
/* 
form::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    left: 0;
    right: 0;
    margin:auto auto;
    max-width:75%;
    z-index: -10;;
    opacity: .4;

} */
textarea{
    width:60%;
    height:80px;
}
p{
    font-size:12px;
   
}
form input[type="text"]{
    width:60%;
    color:black;

}
