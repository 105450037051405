@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Rubik&display=swap);


body{
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  background-image: url(/static/media/backgroundguerrilla.91acc15f.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;

}
@media (max-width: 768px){
  body {
    box-sizing: border-box;
    background-image: url(/static/media/mobilebackground.d89c1372.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  

  }
}


.card{
  width: 600px;
  margin: 40px auto;
  color: #444;
  position: relative;
  overflow: hidden ;
  z-index: 1;
  box-shadow: 2px 2px 0 0 rgb(112, 163, 102);
}
  .card::before {
    content: "";
    position: absolute;
    z-index: -1;
    
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .4;
    background-color: #94be90;
}


h2{
    margin: 0;
    
    padding: 20px;
    text-align: center;
    font-family: Roboto Slab;
}

img{
    float: left;
    width: 200px;
    height: 200px;
    margin: 20px;
    border-radius: 50px;
    position: relative;
    z-index: 1;
    -webkit-animation: grow 0.6s;
            animation: grow 0.6s;
}
.bio{
    position: relative;
    margin: 20px;
    font-family: 'Rubik', sans-serif;
    -webkit-animation: fadeInLeft 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: typewriter 6s steps(150) forwards;
}
.p{
    -webkit-animation: typewriter 6s steps(150) forwards;
            animation: typewriter 6s steps(150) forwards;
    color:#000000;

}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.message-chars-left {
  width: 100%;
  margin: auto;
  text-align: center;
}
.error{
    font-size:small;
    color:red;
    display:inline;
}
.label{
    text-align:left;
}
.input{
    display:-webkit-flex;
    display:flex;
    color:black;
    position:relative;
    min-width: 80px;
    -webkit-justify-content:center;
            justify-content:center;
}
form{
    background-color: #ccc;
    opacity: .8;
    border:1px solid black;
    border-radius: 20px 0;
    text-align: left;
    color:#000;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap:1.5em;
    gap:1.5em;
    z-index:10;
    width: 50%;
    margin: 2em auto;
    padding: 10px;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
form h1{
    color:black
}
/* 
form::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    left: 0;
    right: 0;
    margin:auto auto;
    max-width:75%;
    z-index: -10;;
    opacity: .4;

} */
textarea{
    width:60%;
    height:80px;
}
p{
    font-size:12px;
   
}
form input[type="text"]{
    width:60%;
    color:black;

}

svg{
    
}
form{
    color:white;
    z-index: 100;
    width: 300px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}
h1{
    color:white;
    text-align:center;
    font-size: 150%;
}
input{
    width:40%;
    line-height:20px;
    border-radius: 10px;
    height:20px;
    color:white;
}
